import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 所有菜单
    menus: [],
    // 当前二级菜单
    secondMenus: [],
    // 当前三级菜单
    thirdlyMenus: [],
    firstStage: "",
    secondStage: "",
    thirdlyStage: "",
    webType: 'web'
  },
  getters: {
    // 获取第一级选中数据
    GET_FIIST_STAGE(state) {
      // console.log(state.firstStage)
      // console.log(state.secondStage)
      return state.firstStage;
    },
    // 获取当前是手机还是pc
    GET_WEB_TYPE(state) {
      return state.webType;
    },
    // 获取菜单列表
    GET_MENUS(state) {
      return state.menus;
    },
    // 获取第二级选中数据
    GET_SECOND_STAGE(state) {
      return state.secondStage;
    },
    // 获取第三级选中数据
    GET_THIRDLY_STAGE(state) {
      return state.thirdlyStage;
    },
    // 获取第二级数据
    GET_SECOND_MENUS(state) {
      return state.secondMenus;
    },
    // 获取第三级数据
    GET_THIEDLY_MENUS(state) {
      return state.thirdlyMenus;
    },

  },
  mutations: {
    SET_WEB_TYPE(state, value) {
      state.webType = value;
    },
    // 设置菜单所有项
    SET_MENUS(state, value) {
      state.menus = value;
      // console.log(state.menus)
    },
    // 设置二三级菜单项
    SET_MENUS_ITEM(state, columnId) {
      state.firstStage = "";
      state.secondStage = "";
      state.thirdlyStage = "";

      state.menus.map((item) => {
        if (item.id == columnId) {
          state.firstStage = item.id;
          state.thirdlyStage = "";
          state.secondStage = "";
          state.secondMenus = [];
          state.thirdlyMenus = []
        }
        item.children.map((index) => {
          if (index.id == columnId) {
            state.firstStage = item.id;
            state.secondStage = index.id;
            state.secondMenus = item.children;
            state.thirdlyStage = "";
            state.thirdlyMenus = []
          }
          index.children.map((i) => {
            if (i.id == columnId) {
              state.firstStage = item.id;
              state.secondStage = index.id;
              state.thirdlyStage = i.id;
              state.secondMenus = item.children;
              state.thirdlyMenus = index.children;
            }
          });
        });
      });
    }
  },
  actions: {

  },
  modules: {},
});
